import React from "react";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HOMEPAGE, JOBS } from "../pageRoutes";

const NoApplicationFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: "1em",
        minHeight: "30vh"
      }}
    >
      <SearchOffIcon sx={{ fontSize: 60 }} />
      <Typography variant="body1">
        It seems like you haven’t started applying yet. Begin exploring
        opportunities and track your progress here. Good luck with your job
        search!
      </Typography>

      <Stack
        display="flex"
        width="100%"
        flexDirection={matchSM ? "column" : "row"}
        gap={3}
        justifyContent="center"
        alignItems="center"
        padding="1em"
      >
        <Button
          variant="contained"
          onClick={() => {
            navigate(HOMEPAGE);
          }}
        >
          Go to Homepage
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(JOBS);
          }}
        >
          Search Jobs
        </Button>
      </Stack>
    </Box>
  );
};

export default NoApplicationFound;
