import React from "react";
import {
  Box,
  Typography,
  Button,
  Chip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { APPLY, JOBS } from "../pageRoutes";
import { Job } from "../../Graphql/types/job";
import { formatFilterString } from "./utils/filterutils";

interface JobDetailHeaderProps {
  job: Job;
}

const JobDetailHeader: React.FC<JobDetailHeaderProps> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("sm"));
  const { job } = props;
  const {
    title,
    duration,
    jobLocation,
    jobLevel,
    jobRole,
    jobImage,
    preferredStartDate
  } = job;
  const { jobId } = useParams<{ jobId: string }>();

  const handleApply = () => {
    navigate(`${JOBS}/${jobId}${APPLY}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: "2em",
        borderBottom: "1px solid #e0e0e0",
        flexDirection: matchMD ? "column" : "row",
        width: "100%",
        boxSizing: "border-box"
      }}
    >
      {/* Left Section */}
      <Box
        display="flex"
        alignItems={matchMD ? "left" : "center"}
        gap={2}
        width={matchMD ? "100%" : "auto"}
        padding={matchMD ? "0 0 2em 0" : "auto"}
        justifyContent={matchMD ? "center" : "inherit"}
      >
        {/* Logo */}
        <Box
          component="img"
          src={jobImage}
          alt={`${title} image`}
          sx={{ width: 50, height: 50 }}
        />

        {/* Job Information */}
        <Box gap={2}>
          <Typography variant={matchMD ? "h6" : "h5"} fontWeight="bold">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatFilterString(duration as string)}• {jobLocation}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {`Starts: ${formatFilterString(preferredStartDate || "")}`}
          </Typography>

          {/* Tags */}
          <Box display="flex" gap={1} mt={1}>
            <Chip
              label={formatFilterString(jobLevel as string)}
              size="small"
              sx={{ borderRadius: 0 }}
            />
            <Chip label={formatFilterString(jobRole as string)} size="small" />
          </Box>
        </Box>
      </Box>

      {/* Right Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          justifyContent: matchMD ? "center" : "auto"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: "none", fontWeight: 600 }}
          onClick={handleApply}
        >
          Apply Now
        </Button>
      </Box>
    </Box>
  );
};

export default JobDetailHeader;
