import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Job } from "../../Graphql/types/job";

export interface JobSearchState {
  jobList: Job[];
}

const initialState: JobSearchState = {
  jobList: []
};

export const jobSearchSlice = createSlice({
  name: "jobFilter",
  initialState,
  reducers: {
    setJobList: (state, action: PayloadAction<Job[]>) => {
      state.jobList = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setJobList } = jobSearchSlice.actions;

export default jobSearchSlice.reducer;
