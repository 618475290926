import { ApplicationStatus } from "../../../Graphql/types/application";
import {
  ApplicationStateIdealTranstion,
  ApplicationStatusEdgeCaseTransition,
  ApplicationTerminalStatus
} from "../constants/applicationConstant";

/**
 * Get the application steps to display on the UI.
 *
 * @param currentStatus The current application status.
 * @param previousStatus The previous application status.
 * @returns The application steps as an array of ApplicationStatus.
 */
export function getApplicationStepsV2(
  currentStatus: ApplicationStatus,
  previousStatus: ApplicationStatus
): ApplicationStatus[] {
  // If the status is a terminal status, use the flow for that specific terminal state
  if (ApplicationTerminalStatus.includes(currentStatus)) {
    if (currentStatus === ApplicationStatus.WITHDRAWN) {
      /**
       * For ON_HOLD, fallback to the flow leading to the previous state but withdrawn is included as a last flow
       * Know that withdrawn and onHold are dynamic states. They can happen at the end of any status exceot Hired
       */
      const flow =
        ApplicationStatusEdgeCaseTransition[previousStatus] ||
        ApplicationStateIdealTranstion.slice(
          0,
          ApplicationStateIdealTranstion.indexOf(previousStatus) + 1
        );

      return [...flow, ApplicationStatus.WITHDRAWN];
    }

    // if status is Hired, return the ideal flow even tho it's a terminal flow
    if (
      currentStatus === ApplicationStatus.HIRED ||
      currentStatus === ApplicationStatus.ON_HOLD
    ) {
      return ApplicationStateIdealTranstion;
    }

    // Otherwise, return flows of current status ot default to empty array
    return ApplicationStatusEdgeCaseTransition[currentStatus] || [];
  }

  return ApplicationStateIdealTranstion;
}

export const getActiveApplicationStateIndex = (
  appSteps: ApplicationStatus[],
  currentStatus: ApplicationStatus,
  previousStatus: ApplicationStatus
): number => {
  if (currentStatus === ApplicationStatus.ON_HOLD) {
    return appSteps.indexOf(previousStatus);
  }

  const index = appSteps.indexOf(currentStatus);

  return index === -1 ? 0 : index;
};
