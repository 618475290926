import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Chip, Divider, LinearProgress, Stack } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { useMutation } from "@tanstack/react-query";
import FilterSidebar from "./FilterSidebar";
import JobCard from "./JobCard";
import { getJobListWithFiltersQuery } from "../../Graphql/querries/jobQuery";
import {
  getJobListResponse,
  Job,
  JobSearchRequest
} from "../../Graphql/types/job";
import { fetchGraphQL } from "../../Graphql/utils";
import { useAppDispatch, useAppSelector } from "../../Hooks/reduxHooks";
import { setJobList } from "../../Redux/slices/JobSearchSlice";
import { buildJobSearchRequest } from "./utils/filterutils";
import NoJobsFound from "./NoJobsFound";
import AlertBannerContext from "../../Contexts/alertBannerContext";

const JobList: React.FC = () => {
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false);
  const { jobFilter, jobSearch } = useAppSelector();
  const { setAlert } = useContext(AlertBannerContext);
  const dispatch = useAppDispatch();
  const { jobList } = jobSearch;

  const { mutate, isPending } = useMutation<
    getJobListResponse,
    Error,
    JobSearchRequest
  >({
    mutationKey: ["getJobList"],
    mutationFn: (filters) =>
      fetchGraphQL(getJobListWithFiltersQuery, { input: filters }, setAlert),
    onSuccess: (response) => {
      dispatch(setJobList(response.getAllJobsWithFilter || []));
    },
    onError: () => {
      setJobList([]);
    }
  });

  useEffect(() => {
    const request = buildJobSearchRequest(jobFilter);
    mutate(request);
  }, [jobFilter]);

  return (
    <Box sx={{ display: "flex" }}>
      {/* Sidebar */}
      <FilterSidebar
        mobileOpen={mobileFilterOpen}
        setMobileOpen={setMobileFilterOpen}
      />
      <Divider orientation="vertical" variant="middle" flexItem />
      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={{ sm: 0, md: 0, lg: 0 }}
        >
          <Chip
            sx={{
              display: { sm: "flex", md: "none", lg: "none" },
              padding: "1em"
            }}
            icon={<TuneIcon fontSize="small" />}
            label="Filters"
            variant="outlined"
            color="primary"
            onClick={() => setMobileFilterOpen(true)}
          />
        </Box>

        <Grid
          container
          spacing={{ sm: 0, md: 3, lg: 3 }}
          sx={{ width: "100%", margin: "auto", paddingTop: 3 }}
        >
          {isPending && (
            <Stack width="100%" paddingBottom="1em" alignSelf="center">
              <LinearProgress />
            </Stack>
          )}
          {jobList.length
            ? jobList.map((job: Job) => (
                <Grid item xs={12} md={6} lg={4} key={job.title}>
                  <JobCard job={job} />
                </Grid>
              ))
            : !isPending && <NoJobsFound />}
        </Grid>
      </Box>
    </Box>
  );
};

export default JobList;
