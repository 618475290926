import React, { useContext } from "react";
import {
  Box,
  Button,
  Container,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { CountryCode } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import urlcat from "urlcat";
import { RegistrationFormData } from "./types";
import { fetchGraphQL } from "../../Graphql/utils";
import { isValidPhoneNumberForCountry } from "../../Utils/phoneValidatorUtil";
import { LOGIN } from "../pageRoutes";
import { registerQuery } from "../../Graphql/querries/authQuery";
import { AddMemberResponse } from "../../Graphql/types/auth";
import AlertBannerContext from "../../Contexts/alertBannerContext";
import useQueryParams from "../../Hooks/useQueryParams";

const Registration: React.FC = () => {
  const countryCode = process.env.REACT_APP_COUNTRY_CODE || "RW";
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertBannerContext);
  const { redirectPage } = useQueryParams();

  const schema: ObjectSchema<RegistrationFormData> = yup.object().shape({
    firstName: yup
      .string()
      .required("Firstname is required")
      .min(2, "Firstname must be at least 2 chars")
      .matches(/^[A-Za-z\s'-]+$/, "Special chars are not allowed"),
    lastName: yup
      .string()
      .required("Lastname is required")
      .min(2, "Lastname must be at least 3 chars")
      .matches(/^[A-Za-z\s'-]+$/, "Special chars are not allowed"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    phoneNumber: yup
      .string()
      .required("PhoneNumber is required")
      .test("is-valid-phone", "Please enter a valid phone number", (value) =>
        value
          ? isValidPhoneNumberForCountry(value, countryCode as CountryCode)
          : false
      )
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(schema)
  });

  const { mutate, isPending } = useMutation<
    AddMemberResponse,
    Error,
    RegistrationFormData
  >({
    mutationKey: ["registration"],
    mutationFn: (input) => fetchGraphQL(registerQuery, { input }, setAlert),
    onSuccess: () => {
      navigate(urlcat(LOGIN, { redirectPage }));
    },
    onError: () => {
      // TODO: Handle registration error
    }
  });

  const handleClick = (form: RegistrationFormData) => {
    mutate(form);
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        // background: "linear-gradient(to bottom, #003973, #E5E5BE)"
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          borderRadius: 2,
          boxShadow: 3,
          overflow: "hidden",
          flexDirection: { xs: "column", md: "row" } // Stack on small screens
        }}
      >
        {/* Left Side */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // background: "url(/path-to-image.jpg) no-repeat center",
            backgroundSize: "cover",
            color: "white",
            padding: 4,
            height: { xs: "15vh", md: "auto" }, // Adjust height for small screens
            minHeight: { xs: "10vh", md: "30vh" },
            background: "rgb(34, 47, 62)"
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            paddingBottom="1em"
            color="white"
          >
            Create account
          </Typography>
          <Typography
            color="white"
            variant="body1"
            textAlign="center"
            gutterBottom
            fontFamily="none"
          >
            Register and stay connected with the Kivunova community!
          </Typography>
        </Box>

        {/* Right Side */}
        <Box
          sx={{
            flex: 1,
            background: "white",
            padding: 4
          }}
        >
          <Stack
            minHeight="150px"
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            display="flex"
            spacing="2em"
          >
            <Container
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Stack
                spacing="2em"
                justifyContent="center"
                alignItems="center"
                padding="2em 0px"
                textAlign="center"
                width="100%"
                maxWidth="600px"
                alignSelf="center"
                component="form"
                onSubmit={handleSubmit(handleClick)}
                noValidate
              >
                <Box
                  component="form"
                  noValidate
                  autoComplete="on"
                  style={{ width: "100%" }}
                >
                  {isPending && (
                    <Stack width="100%" paddingBottom="1em" alignSelf="center">
                      <LinearProgress />
                    </Stack>
                  )}
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.firstName}
                        size="medium"
                        id="outlined-error-helper-text"
                        label="Firstname"
                        variant="outlined"
                        helperText={errors.firstName?.message}
                        type="text"
                        fullWidth
                        margin="normal"
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.lastName}
                        size="medium"
                        id="outlined-error-helper-text"
                        label="Lastname"
                        variant="outlined"
                        helperText={errors.lastName?.message}
                        type="text"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: "1em" }}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.email}
                        size="medium"
                        id="outlined-error-helper-text"
                        label="Email"
                        variant="outlined"
                        helperText={errors.email?.message}
                        type="email"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: "1em" }}
                      />
                    )}
                  />
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.phoneNumber}
                        size="medium"
                        id="outlined-error-helper-text"
                        label="Phone number"
                        variant="outlined"
                        helperText={errors.phoneNumber?.message}
                        type="tel"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: "1em" }}
                      />
                    )}
                  />
                </Box>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{
                    mt: "1.5em",
                    backgroundColor: "rgb(34, 47, 62)", // Custom background color
                    "&:hover": {
                      backgroundColor: "rgb(34 47 62 / 87%)" // Hover background color
                    }
                  }}
                >
                  Submit...
                </Button>
              </Stack>
              <Stack>
                <Typography variant="body2" alignSelf="end">
                  Login <Link href={LOGIN}>here</Link>
                </Typography>
              </Stack>
            </Container>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default Registration;
