import React, { FC, useContext, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  LinearProgress,
  Stack,
  TextareaAutosize,
  TextField,
  Typography
} from "@mui/material";
import Container from "@mui/material/Container";
import { useMutation } from "@tanstack/react-query";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { CountryCode } from "libphonenumber-js";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { addServiceChatBookingResponse } from "../../Graphql/types/serviceChatBooking";
import { fetchGraphQL } from "../../Graphql/utils";
import { addServiceChatBookingQuery } from "../../Graphql/querries/serviceChatBooking";
import { isValidPhoneNumberForCountry } from "../../Utils/phoneValidatorUtil";
import { ServiceChatBookingFormData } from "./types";
import AlertBannerContext from "../../Contexts/alertBannerContext";
import { AlertBannerCodeMap } from "../../Constants/AlertBannertCodeMap";

const ServiceChatBookingForm: FC = () => {
  const countryCode = process.env.REACT_APP_COUNTRY_CODE || "RW";
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));
  const { setAlert, alert } = useContext(AlertBannerContext);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const schema: ObjectSchema<ServiceChatBookingFormData> = yup.object().shape({
    firstName: yup
      .string()
      .required("Firstname is required")
      .min(2, "Firstname must be at least 2 chars")
      .matches(/^[A-Za-z\s'-]+$/, "Special chars are not allowed"),
    lastName: yup
      .string()
      .required("Lastname is required")
      .min(2, "Lastname must be at least 3 chars")
      .matches(/^[A-Za-z\s'-]+$/, "Special chars are not allowed"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    phoneNumber: yup
      .string()
      .required("PhoneNumber is required")
      .test("is-valid-phone", "Please enter a valid phone number", (value) =>
        value
          ? isValidPhoneNumberForCountry(value, countryCode as CountryCode)
          : false
      ),
    message: yup
      .string()
      .required("Message is required")
      .min(3, "Message must be at least 3 chars")
      .matches(/^[A-Za-z\s'-,.?!$():]+$/, "Special chars are not allowed")
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ServiceChatBookingFormData>({
    resolver: yupResolver(schema)
  });

  const { mutate, isPending } = useMutation<
    addServiceChatBookingResponse,
    Error,
    ServiceChatBookingFormData
  >({
    mutationKey: ["addServiceChatBooking"],
    mutationFn: (formData) =>
      fetchGraphQL(addServiceChatBookingQuery, { input: formData }, setAlert),
    onSuccess: () => {
      setShowSuccess(true);
    }
  });

  const handleClick = (form: ServiceChatBookingFormData) => {
    mutate(form);
  };

  return (
    <Stack
      minHeight="150px"
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      display="flex"
      spacing="2em"
    >
      <Container
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {showSuccess ? (
          <Stack
            spacing="2em"
            justifyContent="center"
            alignItems="center"
            padding="2em 0px 0px 0px"
            textAlign="center"
            width="100%"
          >
            <CheckCircleIcon color="success" fontSize="large" />
            <Typography
              fontSize="1em"
              gutterBottom
              variant="body2"
              align="center"
              sx={{ marginBottom: 3, marginTop: 3 }}
              color="gray"
            >
              Thank you, our team will reach out to you soon!
            </Typography>
          </Stack>
        ) : (
          <Stack
            spacing="2em"
            justifyContent="center"
            alignItems="center"
            padding="2em 0px 0px 0px"
            textAlign="center"
            width="100%"
            maxWidth="600px"
            alignSelf="center"
            component="form"
            onSubmit={handleSubmit(handleClick)}
            noValidate
          >
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "100%" }
              }}
              noValidate
              autoComplete="of"
            >
              {isPending && (
                <Stack width="60%" paddingBottom="1em" alignSelf="center">
                  <LinearProgress />
                </Stack>
              )}
              <Stack
                width="100%"
                flexDirection={matchMD ? "column" : "row"}
                gap={2}
              >
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.firstName}
                      size="small"
                      id="outlined-error-helper-text"
                      label="Firstname"
                      variant="outlined"
                      helperText={errors.firstName?.message}
                      type="text"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.lastName}
                      size="small"
                      id="outlined-error-helper-text"
                      label="Lastname"
                      variant="outlined"
                      helperText={errors.lastName?.message}
                      type="text"
                      fullWidth
                    />
                  )}
                />
              </Stack>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email}
                    size="small"
                    id="outlined-error-helper-text"
                    label="Email"
                    variant="outlined"
                    helperText={errors.email?.message}
                    type="email"
                  />
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.phoneNumber}
                    size="small"
                    id="outlined-error-helper-text"
                    label="Phone number"
                    variant="outlined"
                    helperText={errors.phoneNumber?.message}
                    type="tel"
                  />
                )}
              />
              <Controller
                name="message"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <>
                    <TextareaAutosize
                      {...field}
                      maxRows={8}
                      aria-label="Send us a message"
                      placeholder="Send us a message"
                      minRows={5}
                    />
                    {errors.message && (
                      <Typography
                        variant="body2"
                        color="error"
                        width="100%"
                        textAlign="start"
                        paddingLeft="1em"
                        fontSize="0.75rem"
                      >
                        {errors.message.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
            {alert && (
              <FormHelperText sx={{ color: "rgb(211, 47, 47)", pl: "1em" }}>
                {AlertBannerCodeMap[alert.code] ||
                  AlertBannerCodeMap.INTERNAL_SERVER_ERROR}
              </FormHelperText>
            )}

            <Button variant="outlined" type="submit">
              Send it...
            </Button>
          </Stack>
        )}
      </Container>
    </Stack>
  );
};

export default ServiceChatBookingForm;
