import React, { FC, useContext, useState } from "react";
import { Button, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import PageContent from "../Common/PageContent/PageContent";
import YouTubeVideo from "../Common/YoutubeVideo/YoutubeVideo";
import ServiceCard from "./ServiceCard";
import Modal from "../Common/Modal/Modal";
import PageHeading from "../Common/PageHeading/PageHeading";
import ServiceChatBookingForm from "./ServiceChatBookingForm";
import { fetchGraphQL } from "../../Graphql/utils";
import { getAllServiceCategoriesResponse } from "../../Graphql/types/serviceCategory";
import { getAllServiceCategoriesQuery } from "../../Graphql/querries/serviceCategoryQuery";
import AlertBannerContext from "../../Contexts/alertBannerContext";

export const OurServices: FC = () => {
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { setAlert } = useContext(AlertBannerContext);

  const { data, isFetching } = useQuery<getAllServiceCategoriesResponse>({
    queryKey: ["addServiceChatBooking"],
    queryFn: () => fetchGraphQL(getAllServiceCategoriesQuery, {}, setAlert)
  });

  return (
    <section>
      <PageHeading titleText="Our Services" />
      <PageContent styles={{ background: "white" }}>
        <Stack
          display="flex"
          flexDirection={matchMD ? "column" : "row"}
          paddingTop="1em"
        >
          <Stack
            display="flex"
            flexDirection="column"
            width={matchMD ? "100%" : "50%"}
            padding="2em 0"
            gap="1.5em"
          >
            <Typography
              fontSize="1.75em"
              gutterBottom
              variant="h6"
              fontWeight="regular"
            >
              We partner with you through every stage of your journey
            </Typography>
            <Typography>
              Build your dedicated remote team, with full control and get your
              deliverable save up to 70% on employees costs.
            </Typography>
            <Stack width="fit-content" padding="1em" marginTop="2em">
              <Button onClick={handleOpen} size="large" variant="contained">
                Book a chat with us
              </Button>
              {open && (
                <Modal
                  title="Book a chat with out team today!"
                  closeButton={handleClose}
                >
                  <ServiceChatBookingForm />
                </Modal>
              )}
            </Stack>
          </Stack>
          <Stack width={matchMD ? "100%" : "50%"} padding="2em 0">
            <Container>
              <YouTubeVideo videoId="dQw4w9WgXcQ" />
            </Container>
          </Stack>
        </Stack>
        <Typography
          fontSize="1.75em"
          gutterBottom
          variant="h4"
          align="center"
          sx={{ marginBottom: 3, marginTop: 3 }}
        >
          What services do we offer?
        </Typography>
        <Typography
          variant="subtitle1"
          component="div"
          align="center"
          gutterBottom
          style={{ margin: "3em 0px", textAlign: "center" }}
        >
          At Kivunova, we offer mentorship programs for students, graduates, and
          aspiring engineers, helping them build strong foundations in tech.
          Additionally, we provide outsourcing services to institutions and
          businesses, delivering expert tech solutions to meet their unique
          needs.
        </Typography>

        <Grid container spacing={3}>
          {isFetching && (
            <Stack
              width="100%"
              paddingBottom="1em"
              display="flex"
              justifyContent="center"
            >
              <LinearProgress />
            </Stack>
          )}
          {data?.getAllServiceCategories?.map((service) => (
            <Grid item xs={12} md={4} key={service.title}>
              <ServiceCard
                title={service.title}
                icon={service.icon}
                serviceList={service.serviceItems}
              />
            </Grid>
          ))}
        </Grid>
      </PageContent>
    </section>
  );
};

export default OurServices;
