import React, { FC, useContext } from "react";
import * as yup from "yup";
import {
  Container,
  Stack,
  Typography,
  Box,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import {
  Application,
  UpdateApplicationStatus,
  WithdrawApplicationFormData,
  WithdrawApplicationInput,
  WithdrawApplicationReason,
  WithdrawApplicationResponse
} from "../../Graphql/types/application";
import { formatFilterString } from "../Career/utils/filterutils";
import { fetchGraphQL } from "../../Graphql/utils";
import { withdrawApplicationQuery } from "../../Graphql/querries/applicationQuery";
import AlertBannerContext from "../../Contexts/alertBannerContext";
import {
  ALERT_BANNER_CODE,
  ALERT_BANNER_CODE_SEVERITY
} from "../../Enums/alertCode";

interface WithdrawApplicationProps {
  applicationId: string;
  memberId: string;
  closeModal: () => void;
  updateApplication: (application?: Application) => void;
}

const WithdrawApplication: FC<WithdrawApplicationProps> = ({
  applicationId,
  memberId,
  closeModal,
  updateApplication
}) => {
  const { setAlert } = useContext(AlertBannerContext);
  const schema: yup.ObjectSchema<WithdrawApplicationFormData> = yup
    .object()
    .shape({
      withdrawReason: yup
        .string()
        .oneOf(
          Object.values(WithdrawApplicationReason),
          "Invalid withdraw reason selected"
        )
        .required("Withdraw reason is required")
        .min(2, "Withdraw reason must be at least 2 chars")
        .matches(/^[A-Za-z\s'-_]+$/, "Special chars are not allowed")
    });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<WithdrawApplicationFormData>({
    resolver: yupResolver(schema)
  });

  const { mutate, isPending } = useMutation<
    WithdrawApplicationResponse,
    Error,
    WithdrawApplicationInput
  >({
    mutationKey: ["withdrawApplication"],
    mutationFn: (formData) =>
      fetchGraphQL(withdrawApplicationQuery, { input: formData }, setAlert),
    onSuccess: (response) => {
      const { withdrawApplication } = response;
      const { status, application: responseData } = withdrawApplication;

      if (status === UpdateApplicationStatus.SUCCESS) {
        updateApplication(responseData);
        setAlert({
          code: ALERT_BANNER_CODE.WITHDRAWN_APPLICATION,
          severity: ALERT_BANNER_CODE_SEVERITY.SUCCESS
        });
        closeModal();
      } else {
        setAlert({
          code: ALERT_BANNER_CODE.INTERNAL_ERROR,
          severity: ALERT_BANNER_CODE_SEVERITY.ERROR
        });
      }
    }
  });

  const handleClickSubmit = (formInput: WithdrawApplicationFormData) => {
    const { withdrawReason } = formInput;
    const formVariable: WithdrawApplicationInput = {
      applicationId,
      memberId,
      withdrawReason
    };
    mutate(formVariable);
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "0 2em",
        maxWidth: "100%"
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        gap={4}
        padding="2em 0 1em 0"
      >
        <Typography variant="body1" width="100%">
          Are you sure you want to withdraw your application? This action cannot
          be undone.
        </Typography>
      </Stack>
      <Box
        component="form"
        noValidate
        autoComplete="on"
        style={{ width: "100%" }}
        display="flex"
        flexDirection="column"
        gap={1.7}
        onSubmit={handleSubmit(handleClickSubmit)}
      >
        {isPending && (
          <Stack width="100%" paddingBottom="1em" alignSelf="center">
            <LinearProgress />
          </Stack>
        )}
        <Controller
          name="withdrawReason"
          control={control}
          render={({ field }) => (
            <>
              <FormControl fullWidth style={{ margin: "1em auto 0.7em auto" }}>
                <InputLabel id="withdraw-reason-select-label">
                  Select withdraw reason
                </InputLabel>
                <Select
                  {...field}
                  labelId="withdraw-reason-select-label"
                  error={!!errors.withdrawReason}
                  id="withdraw-reason-select"
                  name="Withdraw reason"
                  label="Select withdraw reason"
                  size="medium"
                  fullWidth
                  style={{ width: "100%!important" }}
                >
                  {Object.values(WithdrawApplicationReason).map((reason) => (
                    <MenuItem key={reason} value={reason}>
                      {formatFilterString(reason)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!!errors.withdrawReason && (
                <FormHelperText sx={{ color: "rgb(211, 47, 47)", pl: "1em" }}>
                  {errors.withdrawReason.message}
                </FormHelperText>
              )}
            </>
          )}
        />
        <Stack
          display="flex"
          flexDirection="row"
          gap={2}
          width="100%"
          justifyContent="center"
        >
          <Button size="large" type="submit" variant="contained">
            Confirm
          </Button>

          <Button size="large" variant="outlined" onClick={closeModal}>
            Cancel
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default WithdrawApplication;
