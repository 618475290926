import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ObjectSchema } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import urlcat from "urlcat";
import { LoginFormData } from "./types";
import { fetchGraphQL } from "../../Graphql/utils";
import { REGISTRATION, VERIFY_OTP } from "../pageRoutes";
import { sendOtpQuery } from "../../Graphql/querries/authQuery";
import AuthContext from "../../Contexts/authContext";
import {
  AUTH_STATUS,
  SendOtpResponse,
  SendOtpVariable
} from "../../Graphql/types/auth";
import AlertBannerContext from "../../Contexts/alertBannerContext";
import useQueryParams from "../../Hooks/useQueryParams";

const Login: React.FC = () => {
  const [formData, setFormData] = useState<LoginFormData | undefined>();
  const navigate = useNavigate();
  const { authContextInfo, updateAuthContextInfo } = useContext(AuthContext);
  const { setAlert } = useContext(AlertBannerContext);
  const { redirectPage } = useQueryParams();

  const schema: ObjectSchema<LoginFormData> = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required")
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema)
  });

  const { mutate, isPending } = useMutation<
    SendOtpResponse,
    Error,
    SendOtpVariable
  >({
    mutationFn: (data) => fetchGraphQL(sendOtpQuery, { input: data }, setAlert),
    mutationKey: ["sendOtp"],
    onSuccess: () => {
      // update context with email value to be accessed by verify-otp page
      if (updateAuthContextInfo) {
        updateAuthContextInfo({
          ...authContextInfo,
          otp: {
            ...authContextInfo.otp,
            email: formData?.email
          }
        });
      }

      navigate(
        urlcat(VERIFY_OTP, {
          redirectPage
        })
      );
    }
  });

  // Always redirect to previous page if user already logged in
  useEffect(() => {
    if (authContextInfo.auth.status === AUTH_STATUS.AUTHENTICATED) {
      navigate(redirectPage || "/");
    }
  }, [authContextInfo]);

  const handleClick = (formInput: LoginFormData) => {
    setFormData(formInput);
    mutate(formInput);
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        height: "85vh",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          borderRadius: 2,
          boxShadow: 3,
          overflow: "hidden",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        {/* Left Side */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // background: "url(/path-to-image.jpg) no-repeat center",
            backgroundSize: "cover",
            color: "white",
            padding: 4,
            height: { xs: "25vh", md: "auto" }, // Adjust height for small screens
            minHeight: { xs: "20vh", md: "35vh" },
            background: "rgb(34, 47, 62)"
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            paddingBottom="1em"
            color="white"
          >
            Login
          </Typography>
          <Typography
            color="white"
            variant="body1"
            textAlign="center"
            gutterBottom
            fontFamily="none"
          >
            Unleash a world of features waiting for you at Kivunova
          </Typography>
        </Box>

        {/* Right Side */}
        <Box
          sx={{
            flex: 1,
            background: "white",
            padding: 4
          }}
        >
          <Stack
            minHeight="150px"
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            display="flex"
            spacing="2em"
          >
            <Container
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Stack
                spacing="2em"
                justifyContent="center"
                alignItems="center"
                padding="2em 0px"
                textAlign="center"
                width="100%"
                alignSelf="center"
                component="form"
                onSubmit={handleSubmit(handleClick)}
                noValidate
              >
                <Box
                  component="form"
                  noValidate
                  autoComplete="on"
                  style={{ width: "100%" }}
                >
                  {isPending && (
                    <Stack width="100%" paddingBottom="1em" alignSelf="center">
                      <LinearProgress />
                    </Stack>
                  )}
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.email}
                        size="medium"
                        id="outlined-error-helper-text"
                        label="Email"
                        variant="outlined"
                        helperText={errors.email?.message}
                        type="email"
                        fullWidth
                        margin="normal"
                        style={{ width: "100%!important" }}
                      />
                    )}
                  />
                </Box>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  fullWidth
                  sx={{
                    mt: "2em",
                    backgroundColor: "rgb(34, 47, 62)", // Custom background color
                    "&:hover": {
                      backgroundColor: "rgb(34 47 62 / 87%)" // Hover background color
                    }
                  }}
                >
                  Login...
                </Button>
              </Stack>
              <Stack>
                <Typography variant="body2" alignSelf="end">
                  Register{" "}
                  <Link href={urlcat(REGISTRATION, { redirectPage })}>
                    here
                  </Link>
                </Typography>
              </Stack>
            </Container>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
