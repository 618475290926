import { ApplicationStatus } from "../../../Graphql/types/application";

const {
  SUBMITTED,
  INTERVIEW,
  VIEWED,
  OFFER,
  WITHDRAWN,
  NOT_SELECTED,
  NOT_CONSIDERED,
  ON_HOLD,
  HIRED
} = ApplicationStatus;

export const WithdrawApplicationRange: ApplicationStatus[] = [
  SUBMITTED,
  VIEWED
];

export const ApplicationStepsWithError: ApplicationStatus[] = [
  WITHDRAWN,
  NOT_CONSIDERED,
  NOT_SELECTED,
  ON_HOLD
];

// -------------- new logic --------

// Define the ideal flow for the application
export const ApplicationStateIdealTranstion: ApplicationStatus[] = [
  SUBMITTED,
  VIEWED,
  INTERVIEW,
  OFFER,
  HIRED
];

// Define flows for other transitions
export const ApplicationStatusEdgeCaseTransition: {
  [key in ApplicationStatus]?: ApplicationStatus[];
} = {
  [NOT_CONSIDERED]: [SUBMITTED, VIEWED, NOT_CONSIDERED],
  [NOT_SELECTED]: [SUBMITTED, VIEWED, INTERVIEW, NOT_SELECTED],
  [WITHDRAWN]: [], // Will use the dynamic previous state logic
  [ON_HOLD]: [] // Will use the dynamic previous state logic
};

// Define terminal states
export const ApplicationTerminalStatus: ApplicationStatus[] = [
  NOT_CONSIDERED,
  NOT_SELECTED,
  WITHDRAWN,
  ON_HOLD,
  HIRED
];
