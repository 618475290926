import React, { useContext } from "react";
import { LinearProgress, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import InnerHTML from "dangerously-set-inner-html";
import { useParams } from "react-router-dom";
import PageContent from "../Common/PageContent/PageContent";
import JobDetailHeader from "./JobDetailHeader";
import { getJobByIdQuery } from "../../Graphql/querries/jobQuery";
import { getJobByIdResponse } from "../../Graphql/types/job";
import { fetchGraphQL } from "../../Graphql/utils";
import AlertBannerContext from "../../Contexts/alertBannerContext";

const JobDetail = () => {
  // Extract the 'id' from the URL parameters
  const { jobId } = useParams<{ jobId: string }>();
  const { setAlert } = useContext(AlertBannerContext);
  const { data, isFetching } = useQuery<getJobByIdResponse>({
    queryKey: ["getJobById"],
    queryFn: () => fetchGraphQL(getJobByIdQuery, { input: jobId }, setAlert)
  });

  if (isFetching) {
    return (
      <Stack width="100%" paddingBottom="1em" alignSelf="center">
        <LinearProgress />
      </Stack>
    );
  }

  if (!data?.getJobById) {
    return <Typography>Job doesn&apos;t exist</Typography>;
  }

  const { description } = data.getJobById;

  return (
    <section>
      <PageContent
        styles={{
          background: "white",
          paddingBottom: "2em",
          gap: "1em",
          display: "flex",
          width: "100%",
          boxSizing: "border-box",
          flexDirection: "column"
        }}
      >
        <JobDetailHeader job={data.getJobById} />
        <Stack>
          <InnerHTML html={description} />
        </Stack>
      </PageContent>
    </section>
  );
};

export default JobDetail;
