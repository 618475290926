import React from "react";
import { Box, Typography, Paper, Chip, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { JOBS } from "../pageRoutes";
import { Job } from "../../Graphql/types/job";
import { getTimeAgo } from "../../Utils/timeUtils";
import { formatFilterString } from "./utils/filterutils";

interface JobCardProps {
  job: Job;
}

const JobCard: React.FC<JobCardProps> = (props) => {
  const { job } = props;
  const {
    title,
    jobLocation,
    totalPay,
    jobRole,
    jobLevel,
    id: jobId,
    duration,
    postingDate
  } = job;
  const navigate = useNavigate();

  const handleToJobDetail = (id: string) => {
    navigate(`${JOBS}/${id}`);
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
        gap={3}
      >
        <Box width="100%">
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {formatFilterString(duration)} • {jobLocation}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            style={{ paddingTop: "0.2em" }}
          >
            Posted {getTimeAgo(postingDate)}
          </Typography>
          <Box mt={1}>
            <Chip
              variant="outlined"
              label={formatFilterString(jobLevel)}
              size="small"
              sx={{ mr: 1, padding: 1 }}
            />

            <Chip
              variant="outlined"
              label={formatFilterString(jobRole)}
              size="small"
              sx={{ mr: 1, padding: 1 }}
            />
          </Box>
        </Box>
        <Divider orientation="horizontal" variant="middle" flexItem />
        <Box
          width="100%"
          flexDirection="row"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1" color="primary">
            {totalPay}/m
          </Typography>
          <Chip
            label="View details..."
            sx={{ padding: 1.5 }}
            color="info"
            onClick={() => {
              handleToJobDetail(jobId);
            }}
            variant="outlined"
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default JobCard;
