import React, { FC, ReactNode, useMemo, useState } from "react";
import AlertBannerContext from "../Contexts/alertBannerContext";
import {
  AlertBanner,
  AlertBannerContextType
} from "../Contexts/types/AlertBannerContextType";

const ErrorProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [alert, setAlert] = useState<AlertBanner>();

  const resetAlert = () => {
    setAlert(undefined);
  };

  const contextValue = useMemo((): AlertBannerContextType => {
    return { alert, setAlert, resetAlert };
  }, [alert]);

  return (
    <AlertBannerContext.Provider value={contextValue}>
      {children}
    </AlertBannerContext.Provider>
  );
};

export default ErrorProvider;
