import React, { useState, useEffect, FC, useContext } from "react";
import { Alert } from "@mui/material";
import { AlertBannerCodeMap } from "../../../Constants/AlertBannertCodeMap";
import AlertBannerContext from "../../../Contexts/alertBannerContext";

const AlertBannerText: FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const { alert, resetAlert } = useContext(AlertBannerContext);

  useEffect(() => {
    let timer;
    if (!alert) {
      setIsVisible(false);
    } else {
      // Show the banner and set a timer to hide it after 30 seconds
      setIsVisible(true);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      timer = setTimeout(() => {
        setIsVisible(false);
        resetAlert();
      }, 10000);
    }

    // Cleanup timer to avoid memory leaks
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [alert]);

  if (!isVisible || !alert) return null; // Hide the banner when isVisible is false

  return (
    <Alert
      sx={{ textAlign: "center" }}
      severity={alert.severity}
      onClose={resetAlert}
    >
      {AlertBannerCodeMap[alert.code] ||
        AlertBannerCodeMap.INTERNAL_SERVER_ERROR}
    </Alert>
  );
};

export default AlertBannerText;
