import React, { useCallback, useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Chip
} from "@mui/material";
import { Application } from "../../Graphql/types/application";
import {
  getActiveApplicationStateIndex,
  getApplicationStepsV2
} from "./utils/applicationUtils";
import {
  ApplicationStepsWithError,
  WithdrawApplicationRange
} from "./constants/applicationConstant";
import { formatFilterString } from "../Career/utils/filterutils";
import WithdrawApplication from "./WithdrawApplication";
import Modal from "../Common/Modal/Modal";

interface ApplicationCardProps {
  application: Application;
}

const ApplicationCard: React.FC<ApplicationCardProps> = ({ application }) => {
  const [open, setOpen] = useState(false);
  const [updatedApplication, setUpdatedApplication] = useState<
    Application | undefined
  >(undefined);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // first read from the updatedApplication if it exist
  const {
    jobInfo,
    status,
    id: applicationId,
    memberId,
    previousStatus
  } = updatedApplication || application;
  const { jobTitle, jobLocation, jobLevel, totalPay, jobRole, duration } =
    jobInfo;

  // only application in this status can be withdrawn
  const canWithDraw = WithdrawApplicationRange.includes(status);

  const appSteps = useCallback(
    () => getApplicationStepsV2(status, previousStatus),
    [application, updatedApplication]
  );

  return (
    <Paper
      elevation={2}
      sx={{ padding: "2em", width: "100%", boxSizing: "border-box" }}
    >
      <Box display="flex" gap={3} width="100%" flexDirection="column">
        {/* Header */}
        <Box>
          <Typography variant="h6">{jobTitle}</Typography>
          <Box display="flex" flexDirection="column" gap={0.6} pt="0.5em">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="body2" color="text.secondary">
                {formatFilterString(duration as string)} • {jobLocation}
              </Typography>
              <Typography variant="h6">{totalPay}</Typography>
            </Box>
          </Box>
          <Box>
            <Chip
              variant="outlined"
              label={formatFilterString(jobLevel)}
              size="small"
              sx={{ mr: 1, padding: 1 }}
            />

            <Chip
              variant="outlined"
              label={formatFilterString(jobRole)}
              size="small"
              sx={{ mr: 1, padding: 1 }}
            />
          </Box>
        </Box>

        {/* Stepper Section */}
        <Box mt={3}>
          <Stepper
            activeStep={getActiveApplicationStateIndex(
              appSteps(),
              status,
              previousStatus
            )}
            orientation="horizontal"
            alternativeLabel
          >
            {appSteps().map((label) => {
              const isError = ApplicationStepsWithError.includes(label);
              return (
                <Step key={label}>
                  <StepLabel error={isError}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        {/* Edit Section */}
        {canWithDraw && (
          <Box mt={2} textAlign="right">
            <Button variant="outlined" color="primary" onClick={handleOpen}>
              Withdraw
            </Button>
          </Box>
        )}
      </Box>
      {open && (
        <Modal title="Withdraw Application" closeButton={handleClose}>
          <WithdrawApplication
            applicationId={applicationId}
            memberId={memberId}
            closeModal={handleClose}
            updateApplication={setUpdatedApplication}
          />
        </Modal>
      )}
    </Paper>
  );
};

export default ApplicationCard;
