import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useParams } from "react-router-dom";
import { fetchGraphQL, uploadFileGraphQL } from "../../Graphql/utils";
import {
  ApplicationFormData,
  CreateApplicationResponse,
  CreateApplicationVariable,
  EducationDegree
} from "../../Graphql/types/application";

import "./styles/apply.scss";
import { createApplicationQuery } from "../../Graphql/querries/applicationQuery";
import WithAuth from "../../Hoc/WithAuth";
import AuthContext from "../../Contexts/authContext";
import AlertBannerContext from "../../Contexts/alertBannerContext";
import { AlertBannerCodeMap } from "../../Constants/AlertBannertCodeMap";
import { formatFilterString } from "./utils/filterutils";
import { APPLY_SUCCESS } from "../pageRoutes";

const ApplyJob = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formData, setFormData] = useState<ApplicationFormData | undefined>();
  const [file, setFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const { authContextInfo } = useContext(AuthContext);
  // Extract the 'id' from the URL parameters
  const { jobId } = useParams<{ jobId: string }>();
  const { setAlert, alert } = useContext(AlertBannerContext);

  const urlRegex = /https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/\S*)?/;
  const FILE_SIZE = 1 * 1024 * 1024; // 2MB in bytes
  const SUPPORTED_FORMATS = ["PDF", "DOC", "DOCX"]; // .docx];

  const formFileSupportedFormats = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/msword"
  ];

  const schema: yup.ObjectSchema<ApplicationFormData> = yup.object().shape({
    schoolName: yup
      .string()
      .required("School name is required")
      .min(2, "School name must be at least 2 chars")
      .matches(/^[A-Za-z\s'-]+$/, "Special chars are not allowed"),
    degree: yup
      .string()
      .oneOf(Object.values(EducationDegree), "Invalid degree selected")
      .required("Degree is required")
      .min(2, "Degree must be at least 2 chars")
      .matches(/^[A-Za-z\s'-_]+$/, "Special chars are not allowed"),
    concentration: yup
      .string()
      .required("Concentration is required")
      .min(2, "Concentration must be at least 2 chars")
      .matches(/^[A-Za-z\s'-]+$/, "Special chars are not allowed"),
    linkedInUrl: yup.lazy((value) => {
      if (value && value.trim() !== "") {
        return yup
          .string()
          .matches(urlRegex, "Linkedin url must be a valid URL");
      }
      return yup.string().optional();
    }),
    githubUrl: yup.lazy((value) => {
      if (value && value.trim() !== "") {
        return yup.string().matches(urlRegex, "Github url must be a valid URL");
      }
      return yup.string().optional();
    }),
    portfolioUrl: yup.lazy((value) => {
      if (value && value.trim() !== "") {
        return yup
          .string()
          .matches(urlRegex, "Portifolio url must be a valid URL");
      }
      return yup.string().optional();
    }),
    resume: yup
      .mixed<File>()
      .required("Please attach your resume!")
      .test(
        "fileSize",
        "File size is too large (max 2MB)",
        (value) => !value || (value && value.size <= FILE_SIZE)
      )
      .test(
        "fileType",
        "Unsupported file format. Only word doc and PDF are allowed",
        (value) =>
          !value || (value && formFileSupportedFormats.includes(value.type))
      )
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ApplicationFormData>({
    resolver: yupResolver(schema)
  });

  const { setValue } = useForm<ApplicationFormData>({
    resolver: yupResolver(schema)
  });

  const handleUploadFile = (fileInput: File) => {
    setFile(fileInput);
    setValue("resume", fileInput, { shouldValidate: true }); // Update form state
  };

  const { mutate, isPending } = useMutation<
    CreateApplicationResponse,
    Error,
    CreateApplicationVariable
  >({
    mutationFn: (data) =>
      file
        ? uploadFileGraphQL(
            createApplicationQuery,
            { input: data },
            file,
            setAlert
          )
        : fetchGraphQL(createApplicationQuery, { input: data }, setAlert),
    mutationKey: ["createApplication"],
    onSuccess: () => {
      navigate(APPLY_SUCCESS);
    }
  });

  const handleClickSubmit = (formInput: ApplicationFormData) => {
    if (jobId && authContextInfo.auth.memberId) {
      const {
        linkedInUrl,
        portfolioUrl,
        githubUrl,
        schoolName,
        degree,
        concentration
      } = formInput;
      const formVariable: CreateApplicationVariable = {
        jobId,
        memberId: authContextInfo.auth.memberId,
        linkedInUrl,
        portfolioUrl,
        githubUrl,
        schoolName,
        degree,
        concentration
      };
      mutate(formVariable);
    }

    setFormData(formInput);
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "0 2em",
        maxWidth: "100%"
      }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        gap={4}
        padding="2em 0 1em 0"
      >
        <Typography variant="h6" textAlign="center" width="100%">
          Get started with us today..
        </Typography>
        <Typography variant="body1" width="100%">
          We’ve made applying quick and efficient. Simply share your{" "}
          <b>LinkedIn profile</b>, <b>portfolio URL</b>, <b>GitHub link</b>, and
          attach your <b>resume</b>. This streamlined process allows us to focus
          on your skills and experience, ensuring a smooth and hassle-free
          application experience.
        </Typography>
      </Stack>
      <Box
        component="form"
        noValidate
        autoComplete="on"
        style={{ width: "100%" }}
        display="flex"
        flexDirection="column"
        gap={1.7}
        onSubmit={handleSubmit(handleClickSubmit)}
      >
        {isPending && (
          <Stack width="100%" paddingBottom="1em" alignSelf="center">
            <LinearProgress />
          </Stack>
        )}
        <Stack>
          <Typography variant="h6">Highest Education</Typography>
          <Stack>
            <Controller
              name="schoolName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.schoolName}
                  size="medium"
                  id="schoolName"
                  label="School/University"
                  variant="outlined"
                  helperText={errors.schoolName?.message}
                  type="text"
                  fullWidth
                  margin="normal"
                  style={{ width: "100%!important" }}
                />
              )}
            />
            <Controller
              name="degree"
              control={control}
              render={({ field }) => (
                <>
                  <FormControl
                    fullWidth
                    style={{ margin: "1em auto 0.7em auto" }}
                  >
                    <InputLabel id="degree-select-label">
                      Select degree
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="degree-select-label"
                      error={!!errors.degree}
                      id="degree-select"
                      name="degree"
                      label="Select degree"
                      size="medium"
                      fullWidth
                      style={{ width: "100%!important" }}
                    >
                      {Object.values(EducationDegree).map((degree) => (
                        <MenuItem key={degree} value={degree}>
                          {formatFilterString(degree)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {!!errors.degree && (
                    <FormHelperText
                      sx={{ color: "rgb(211, 47, 47)", pl: "1em" }}
                    >
                      {errors.degree.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />

            <Controller
              name="concentration"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.concentration}
                  size="medium"
                  id="concentration"
                  label="Concentration/Major"
                  variant="outlined"
                  helperText={errors.concentration?.message}
                  type="text"
                  fullWidth
                  margin="normal"
                  style={{ width: "100%!important" }}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="h6">Projects & Skills (Optional)</Typography>
          <Stack>
            <Controller
              name="linkedInUrl"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.linkedInUrl}
                  size="medium"
                  id="linkedinUrl"
                  label="Linkedin URL"
                  variant="outlined"
                  helperText={errors.linkedInUrl?.message}
                  type="text"
                  fullWidth
                  margin="normal"
                  style={{ width: "100%!important" }}
                />
              )}
            />
            <Controller
              name="githubUrl"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.portfolioUrl}
                  size="medium"
                  id="githubUrl"
                  label="Github URL"
                  variant="outlined"
                  helperText={errors.githubUrl?.message}
                  type="text"
                  fullWidth
                  margin="normal"
                  style={{ width: "100%!important" }}
                />
              )}
            />
            <Controller
              name="portfolioUrl"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.portfolioUrl}
                  size="medium"
                  id="githubUrl"
                  label="Portfolio URL"
                  variant="outlined"
                  helperText={errors.portfolioUrl?.message}
                  type="text"
                  fullWidth
                  margin="normal"
                  style={{ width: "100%!important" }}
                />
              )}
            />
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="h6">Resume</Typography>
          <Stack>
            <Controller
              name="resume"
              control={control}
              render={({ field }) => (
                <Container
                  style={{
                    width: "100%!important",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "1em",
                    flexDirection: "column",
                    gap: 1
                  }}
                >
                  <FileUploader
                    handleChange={(fileData) => {
                      handleUploadFile(fileData);
                      field.onChange(fileData);
                    }}
                    types={SUPPORTED_FORMATS}
                    maxSize={FILE_SIZE}
                    label={
                      errors.resume?.message ||
                      "Upload or drop your resume here"
                    }
                    uploadedLabel="Resume uploaded successfully"
                    hoverTiltle="Drop your resume here"
                    required
                    disabled={false}
                    classes="fileUploader"
                    onTypeError={(error) => {
                      /* eslint-disable no-console */
                      console.log("Used wrong type", error);
                    }}
                    onSizeError={(fileItem: File) => {
                      /* eslint-disable no-console */
                      console.log("File fize is limited", fileItem.size);
                    }}
                  />
                  {!!file && (
                    <Typography
                      variant="body2"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        wordWrap: "break-word",
                        padding: "1.2em 0 0 0"
                      }}
                    >
                      <b>Uploaded: </b>
                      {`${file.name}`}
                    </Typography>
                  )}
                  {!!errors.resume?.message && (
                    <Typography
                      variant="body2"
                      width="100%"
                      textAlign="center"
                      color="rgb(211, 47, 47)"
                      padding="1.2em 0 0 0"
                    >
                      {errors.resume?.message}
                    </Typography>
                  )}
                  {!!alert && (
                    <Typography
                      variant="body2"
                      width="100%"
                      textAlign="center"
                      color="rgb(211, 47, 47)"
                      padding="1.2em 0 0 0"
                    >
                      {AlertBannerCodeMap[alert.code] ||
                        AlertBannerCodeMap.INTERNAL_SERVER_ERROR}
                    </Typography>
                  )}
                </Container>
              )}
            />
          </Stack>
        </Stack>
        <Stack>
          <Button
            size="large"
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: "2em",
              backgroundColor: "rgb(34, 47, 62)", // Custom background color
              "&:hover": {
                backgroundColor: "rgb(34 47 62 / 87%)" // Hover background color
              }
            }}
          >
            Submit Application
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default WithAuth(ApplyJob);
