import React from "react";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { Box, Typography } from "@mui/material";

const NoJobsFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        padding: "1em",
        minHeight: "30vh"
      }}
    >
      <SearchOffIcon sx={{ fontSize: 60 }} />
      <Typography variant="body1">
        We couldn&apos;t find any jobs that match your filters. Please adjust
        your filters for better results.
      </Typography>
    </Box>
  );
};

export default NoJobsFound;
