import {
  ABOUT_US,
  APPLICATION_LIST,
  JOBS,
  LOGOUT,
  MEET_OUR_TEAM,
  OUR_SERVICES,
  TECHNOLOGIES
} from "../../pageRoutes";
import { NavigationConfig } from "../types/navigationConfigType";

export const NavigationConfigList: NavigationConfig[] = [
  {
    title: "Our services",
    path: OUR_SERVICES
  },
  {
    title: "Technologies",
    path: TECHNOLOGIES
  },
  // {
  //     title: 'Join us',
  //     path: 'join-us'
  // },
  // {
  //     title: 'Hire us',
  //     path: 'hire-us'
  // },
  // {
  //     title: 'Events',
  //     isMultiLevel: true,
  //     subLinks: [
  //         {
  //             title: 'Kivu Tech corner',
  //             path: 'kivu-tech-corner'
  //         },
  //         {
  //             title: 'Mentorship',
  //             path: 'mentorship'
  //         },
  //         {
  //             title: 'Career connect',
  //             path: 'career-connect'
  //         }
  //     ]
  // },
  {
    title: "Career",
    path: JOBS
  },
  {
    title: "Who we are",
    isMultiLevel: true,
    subLinks: [
      {
        title: "About us",
        path: ABOUT_US
      },
      {
        title: "Meet our team",
        path: MEET_OUR_TEAM
      }
    ]
  }
];

export const LoggedInNavItemConfig: NavigationConfig[] = [
  {
    title: "My Account",
    isMultiLevel: true,
    subLinks: [
      {
        title: "My applications",
        path: APPLICATION_LIST
      },
      // {
      //   title: "My Account",
      //   path: "my-account"
      // },
      {
        title: "Logout",
        path: LOGOUT
      }
    ]
  }
];

export const NotLoggedInNavItemConfig: NavigationConfig[] = [
  {
    title: "Login/Register",
    path: "login"
  }
];
