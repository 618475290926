import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  useMediaQuery,
  useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  EmploymentDuration,
  EmploymentPosition,
  EmploymentPositionLevel
} from "../../Graphql/types/employee";
import { formatFilterString } from "./utils/filterutils";
import { useAppDispatch, useAppSelector } from "../../Hooks/reduxHooks";
import {
  initialFilterState,
  JobFilterState,
  resetFilters,
  setFilterState
} from "../../Redux/slices/JobFilterSlice";

interface FilterSidebarProps {
  mobileOpen: boolean;
  setMobileOpen: (status: boolean) => void;
}

const FilterSidebar: React.FC<FilterSidebarProps> = (props) => {
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down("sm"));
  const { mobileOpen, setMobileOpen } = props;
  const { jobFilter } = useAppSelector();
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState<JobFilterState>(jobFilter);
  const { jobLevel, jobRole, duration } = filters;

  useEffect(() => {
    setFilters(jobFilter);
  }, [jobFilter]);

  const drawerContent = (
    <Box
      sx={{
        width: "100%",
        p: "2em 1em 2em 1em",
        maxWidth: "100%",
        boxSizing: "border-box",
        overflowY: "scroll"
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          display: matchSM ? "center" : "left",
          pb: "1em"
        }}
      >
        Filters
      </Typography>

      <Box display="flex" gap={3} flexDirection="column">
        <Stack>
          <Typography variant="subtitle1">Job Duration</Typography>
          <FormGroup>
            {Object.values(EmploymentDuration).map((filter) => (
              <FormControlLabel
                control={<Checkbox />}
                label={formatFilterString(filter)}
                checked={duration[filter]}
                onChange={() => {
                  setFilters({
                    ...filters,
                    duration: {
                      ...duration,
                      [filter]: !duration[filter]
                    }
                  });
                }}
              />
            ))}
          </FormGroup>
        </Stack>

        <Stack>
          <Typography variant="subtitle1">Position</Typography>
          <FormGroup>
            {Object.values(EmploymentPosition).map((item) => (
              <FormControlLabel
                control={<Checkbox />}
                label={formatFilterString(item)}
                checked={jobRole[item]}
                onChange={() => {
                  setFilters({
                    ...filters,
                    jobRole: {
                      ...jobRole,
                      [item]: !jobRole[item]
                    }
                  });
                }}
              />
            ))}
          </FormGroup>
        </Stack>
        <Stack>
          <FormGroup>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select"
                value={jobLevel}
                label="Age"
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    jobLevel: e.target.value as EmploymentPositionLevel
                  });
                }}
                size="small"
              >
                {Object.values(EmploymentPositionLevel).map((filter) => (
                  <MenuItem
                    selected={filter === filters.jobLevel}
                    value={filter}
                  >
                    {formatFilterString(filter)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Stack>
      </Box>
      <Stack
        sx={{
          display: "flex",
          flexDirection: matchSM ? "row" : "column",
          gap: "1em",
          pt: "3em",
          pb: "0em",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            if (matchSM) setMobileOpen(false);
            dispatch(setFilterState(filters));
          }}
        >
          Apply filters
        </Button>
        <Button
          size="large"
          variant="outlined"
          onClick={() => {
            if (matchSM) setMobileOpen(false);
            dispatch(resetFilters());
            setFilters(initialFilterState);
          }}
        >
          Reset
        </Button>
      </Stack>
    </Box>
  );

  return (
    <>
      {/* Flyout Drawer */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        variant="temporary"
        ModalProps={{
          keepMounted: true // Better open performance on mobile
        }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%"
          },
          display: { xs: "flex", md: "none", lg: "none" },
          justifyContent: "end"
        }}
      >
        <Stack
          padding="2em 2em 0 2em"
          justifyContent="end"
          width="100%"
          display={{ sx: "flex", lg: "none", md: "none" }}
          flexDirection="row"
          boxSizing="border-box"
          onClick={() => {
            setMobileOpen(false);
          }}
        >
          <CloseIcon fontSize="large" />
        </Stack>
        {drawerContent}
      </Drawer>

      {/* Permanent Sidebar for Desktop */}
      <Box
        sx={{
          display: { xs: "none", md: "flex", lg: "100%" },
          width: 300,
          height: "100%"
        }}
      >
        {drawerContent}
      </Box>
    </>
  );
};

export default FilterSidebar;
