export const getJobListQuery = `
query GetAllJobs {
    getAllJobs {
        id
        title
        postingDate
        totalPay
        jobLocation
        jobRole
        jobLevel
        duration
    }
}
`;

export const getJobListWithFiltersQuery = `
query GetAllJobsWithFilter($input: JobFilterInput!) {
    getAllJobsWithFilter(input: $input) {
        id
        title
        postingDate
        totalPay
        jobLocation
        jobRole
        jobLevel
        duration
    }
}
`;

export const getJobByIdQuery = `
query GetJobById($input: ID!) {
    getJobById(id: $input) {
        id
        title
        description
        postingDate
        totalPay
        postingStatus
        jobLocation
        jobImage
        jobRole
        jobLevel
        jobType
        duration
        preferredStartDate
    }
}
`;
