export enum ALERT_BANNER_CODE {
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  FAIL_TO_SEND_EMAIL = "FAIL_TO_SEND_EMAIL",
  MEMBER_NOT_FOUND = "MEMBER_NOT_FOUND",
  INVALID_OTP = "INVALID_OTP",
  ACCESS_DENIED = "ACCESS_DENIED",
  ALREADY_APPLIED = "ALREADY_APPLIED",
  FAILED_TO_UPLOAD_FILE_TO_S3 = "FAILED_TO_UPLOAD_FILE_TO_S3",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  INVALID_OR_UNPOSTED_JOB = "INVALID_OR_UNPOSTED_JOB",
  NOT_FOUND = "NOT_FOUND",
  WITHDRAWN_APPLICATION = "WITHDRAWN_APPLICATION"
}

export enum ALERT_BANNER_CODE_SEVERITY {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error"
}
