export enum ApplicationStatus {
  SUBMITTED = "SUBMITTED",
  VIEWED = "VIEWED",
  NOT_CONSIDERED = "NOT_CONSIDERED",
  WITHDRAWN = "WITHDRAWN",
  INTERVIEW = "INTERVIEW",
  NOT_SELECTED = "NOT_SELECTED",
  HIRED = "HIRED",
  OFFER = "OFFER",
  ON_HOLD = "ON_HOLD"
}

export enum EducationDegree {
  HIGH_SCHOOL = "HIGH_SCHOOL",
  BACHELORS = "BACHELORS",
  MASTERS = "MASTERS",
  ASSOCIATE = "ASSOCIATE",
  PHD = "PHD"
}

export enum WithdrawApplicationReason {
  FOUND_ANOTHER_OPPORTUNITY = "FOUND_ANOTHER_OPPORTUNITY",
  CHANGE_IN_CAREER_GOALS = "CHANGE_IN_CAREER_GOALS",
  NOT_INTERESTED_IN_THIS_ROLE = "NOT_INTERESTED_IN_THIS_ROLE",
  OTHER = "OTHER"
}

export enum ApplicationStatusSteps {
  OFFER = "Offer",
  INTERVIEW = "Interview",
  IN_REVIEW = "In review",
  SUBMITTED = "Submitted",
  NOT_SELECTED = "Not selected",
  NOT_CONSIDERED = "Not considered",
  WITHDRAWN = "Withdrawn",
  HIRED = "Hired",
  ON_HOLD = "On hold"
}

export enum UpdateApplicationStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  APPLICATION_NOT_FOUND = "APPLICATION_NOT_FOUND"
}

interface ApplicationJobInfo {
  jobTitle: string;
  jobLocation: string;
  totalPay: string;
  jobRole: string;
  jobLevel: string;
  jobType: string;
  duration: string;
}

export interface Application {
  id: string;
  jobId: string;
  memberId: string;
  resumeUrl: string;
  status: ApplicationStatus;
  lastUpdated: string;
  submittedDate: string;
  lastUpdateBy: string;
  linkedInUrl: string;
  portfolioUrl: string;
  schoolName: string;
  degree: EducationDegree;
  concentration: string;
  jobInfo: ApplicationJobInfo;
  withdrawReason: WithdrawApplicationReason;
  previousStatus: ApplicationStatus;
}

export interface CreateApplicationResponse {
  createApplication: {
    application: Application;
  };
}

export interface ApplicationFormData {
  schoolName: string;
  degree: EducationDegree;
  concentration: string;
  linkedInUrl?: string;
  portfolioUrl?: string;
  githubUrl?: string;
  resume: File;
}

export interface CreateApplicationVariable {
  linkedInUrl?: string;
  portfolioUrl?: string;
  githubUrl?: string;
  jobId: string;
  memberId: string;
  schoolName: string;
  degree: EducationDegree;
  concentration: string;
}

export interface UpdateApplicationInput {
  applicationId: string;
  status: ApplicationStatus;
}

export interface UpdateApplicationResponse {
  status: UpdateApplicationStatus;
  application: Application;
}

export interface GetApplicationListResponse {
  getApplicationListByMemberId: Application[];
}

export interface WithdrawApplicationInput {
  applicationId: string;
  memberId: string;
  withdrawReason: WithdrawApplicationReason;
}

export interface WithdrawApplicationFormData {
  withdrawReason: WithdrawApplicationReason;
}

export interface WithdrawApplicationResponse {
  withdrawApplication: UpdateApplicationResponse;
}
