import React, { FC, useContext, useEffect } from "react";
import { Tabs, Tab, Box, LinearProgress, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import ApplicationCard from "./ApplicationCard";
import PageContent from "../Common/PageContent/PageContent";
import PageHeading from "../Common/PageHeading/PageHeading";
import WithAuth from "../../Hoc/WithAuth";
import { fetchGraphQL } from "../../Graphql/utils";
import { getApplicationListByMemberIdQuery } from "../../Graphql/querries/applicationQuery";
import AlertBannerContext from "../../Contexts/alertBannerContext";
import AuthContext from "../../Contexts/authContext";
import { GetApplicationListResponse } from "../../Graphql/types/application";
import { AUTH_STATUS } from "../../Graphql/types/auth";
import NoApplicationFound from "./NoApplicationFound";

const ApplicationList: FC = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { setAlert } = useContext(AlertBannerContext);
  const { authContextInfo } = useContext(AuthContext);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const { mutate, data, isPending } = useMutation<
    GetApplicationListResponse,
    Error,
    string
  >({
    mutationKey: ["getApplicationListByMemberId"],
    mutationFn: (memberId) =>
      fetchGraphQL(getApplicationListByMemberIdQuery, { memberId }, setAlert)
  });

  useEffect(() => {
    if (
      authContextInfo.auth.status === AUTH_STATUS.AUTHENTICATED &&
      authContextInfo.auth.memberId
    ) {
      mutate(authContextInfo.auth.memberId);
    }
  }, [authContextInfo.auth.status, authContextInfo.auth.memberId]);

  return (
    <section>
      <PageHeading titleText="Manage your applications" />
      <PageContent styles={{ background: "white" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          style={{ padding: "1.5em 0 1em 0" }}
          centered
        >
          <Tab label="All Applications" />
        </Tabs>
        <Box mt={3} display="flex" flexDirection="column" gap={3}>
          {isPending && (
            <Stack
              width="100%"
              paddingBottom="1em"
              display="flex"
              justifyContent="center"
            >
              <LinearProgress />
            </Stack>
          )}
          {data?.getApplicationListByMemberId?.length
            ? data?.getApplicationListByMemberId.map((app) => (
                <ApplicationCard key={app.id} application={app} />
              ))
            : !isPending && <NoApplicationFound />}
        </Box>
      </PageContent>
    </section>
  );
};

export default WithAuth(ApplicationList);
