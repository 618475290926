import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  JobDurationFilter,
  JobLevelFilter,
  JobRoleFilter
} from "../../Graphql/types/job";
import {
  EmploymentDuration,
  EmploymentPosition,
  EmploymentPositionLevel
} from "../../Graphql/types/employee";

export interface JobFilterState {
  jobRole: JobRoleFilter;
  duration: JobDurationFilter;
  jobLevel: JobLevelFilter;
}

export const initialFilterState: JobFilterState = {
  jobRole: {
    [EmploymentPosition.ACCOUNTANT]: false,
    [EmploymentPosition.CUSTOMER_SERVICE]: false,
    [EmploymentPosition.HR]: false,
    [EmploymentPosition.PM]: false,
    [EmploymentPosition.SDE]: false
  },
  duration: {
    [EmploymentDuration.FULL_TIME]: false,
    [EmploymentDuration.PART_TIME]: false
  },
  jobLevel: ""
};

export const jobFilterSlice = createSlice({
  name: "jobFilter",
  initialState: initialFilterState,
  reducers: {
    setJobRole: (state, action: PayloadAction<JobRoleFilter>) => {
      state.jobRole = action.payload;
    },
    setDuration: (state, action: PayloadAction<JobDurationFilter>) => {
      state.duration = action.payload;
    },
    setJobLevel: (state, action: PayloadAction<EmploymentPositionLevel>) => {
      state.jobLevel = action.payload;
    },
    setFilterState: (state, action: PayloadAction<JobFilterState>) => {
      Object.assign(state, action.payload);
    },
    resetFilters: (state) => {
      Object.assign(state, initialFilterState);
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setDuration,
  setJobLevel,
  setJobRole,
  setFilterState,
  resetFilters
} = jobFilterSlice.actions;

export default jobFilterSlice.reducer;
