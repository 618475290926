import {
  EmploymentDuration,
  EmploymentPosition,
  EmploymentPositionLevel
} from "../../../Graphql/types/employee";
import { JobSearchRequest } from "../../../Graphql/types/job";
import { JobFilterState } from "../../../Redux/slices/JobFilterSlice";

export const formatFilterString = (input: string): string => {
  return input
    .replace(/_/g, " ") // Replace underscores with spaces
    .toLowerCase() // Convert entire string to lowercase
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first character of each word
};

export const buildJobSearchRequest = (
  filters: JobFilterState
): JobSearchRequest => {
  const { jobLevel, jobRole, duration } = filters;

  return {
    jobRole: Object.keys(jobRole)
      .filter((key) => jobRole[key])
      .map((key) => key as EmploymentPosition),
    duration: Object.keys(duration)
      .filter((key) => duration[key])
      .map((key) => key as EmploymentDuration),
    jobLevel: jobLevel ? (jobLevel as EmploymentPositionLevel) : undefined
  };
};
